import { SelectButton } from '@pergas-common/pergas-components'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useDebounce from '../../hooks/useDebounce.js'
import { selectLocale } from '../../redux/locale/selectors.js'

const FilterNumber = ({ options = [], defaultInput, applyFilter }) => {
  const locale = useSelector(selectLocale)
  const [debounced] = useDebounce(applyFilter, 300)
  const [input, setInput] = useState('')
  const [option, setOption] = useState(options[0] ?? '')
  return (
    <>
      <SelectButton
        label={locale.estimate_value}
        defaultInput={defaultInput}
        buttonLabel={option}
        items={options}
        handleSelectedItemChange={({ selectedItem }) => {
          setOption(selectedItem)

          if (input === '') {
            debounced({ input: '', op: null })
            return
          }

          debounced({ input, op: selectedItem })
        }}
        onInputChange={(e) => {
          if (e.currentTarget.value === '') {
            setInput('')
            debounced({ input: '', op: option })
            return
          }
          setInput(e.currentTarget.value)
          debounced({ input: e.currentTarget.value, op: option })
        }}
      />
    </>
  )
}

export default FilterNumber
