import React from 'react'
import {
  styled,
  Waves,
  Grid
} from '@pergas-common/pergas-components'

const WaveHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const withDataGrid = (Component) => {
  return (props) => {
    const [result, isLoading] = props.request(props)
    return (
      <div>
        {isLoading ? <WaveHolder><Waves width={40} height={40} /></WaveHolder> : <Grid.Content columns={props.columns}><Component result={result} /></Grid.Content>}
      </div>
    )
  }
}
