import React from 'react'
import { useSelector } from 'react-redux'
import {
  styled,
  spacing
} from '@pergas-common/pergas-components'
import { selectLocale } from '../../redux/locale/selectors'
import { Radio } from '../Input'

const Label = styled.label`
  display: flex;
  align-self: stretch;
  margin-left: ${spacing(3)};
  height: 100%;
`

const InputHolder = styled.div`
  display: inline-flex;
  align-items: center;
  > small {
    margin-left: ${spacing(1)};
  }
`

const All = ({ applyFilter, checked = false }) => {
  const locale = useSelector(selectLocale)
  return (
    <>
      <Label>
        <InputHolder>
          <Radio
            label={locale.all} value={checked} checked={checked} onChange={() => {
              applyFilter()
            }}
          />
          <small>{locale.all}</small>
        </InputHolder>
      </Label>
    </>
  )
}

export default All
