import { pageDefault } from '../common.js'
import {
  CONTENT_TYPE_GET_PAGE_ITEMS_ACTION,
  CONTENT_TYPE_CLEAR_ITEMS_ACTION,
  CONTENT_TYPE_SET_ORDER_ACTION,
  CONTENT_TYPE_SET_LIMIT_ACTION,
  CONTENT_TYPE_SET_OFFSET_ACTION,
  CONTENT_TYPE_SET_SEARCH_ACTION,
  CONTENT_TYPE_RESET_SEARCH_ACTION
} from './actions.js'

/**
 * Reducer for handling content types.
 */
export function contentType (state, action) {
  if (!state) {
    state = pageDefault('content_type_name', 'content-type')
  }
  switch (action.type) {
    case CONTENT_TYPE_GET_PAGE_ITEMS_ACTION:
      return { ...state, pageItems: action.pageItems }
    case CONTENT_TYPE_CLEAR_ITEMS_ACTION:
      return { ...state, pageItems: [] }
    case CONTENT_TYPE_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return { ...state, order, orderBy, offset: 0 }
    }
    case CONTENT_TYPE_SET_LIMIT_ACTION:
      return { ...state, limit: action.limit, offset: 0 }
    case CONTENT_TYPE_SET_OFFSET_ACTION:
      return { ...state, offset: action.offset }
    case CONTENT_TYPE_SET_SEARCH_ACTION:
      return { ...state, search: action.search, offset: 0 }
    case CONTENT_TYPE_RESET_SEARCH_ACTION:
      return { ...state, search: '', offset: 0 }

    default:
      return state
  }
}
