import React, { memo, Fragment, useCallback, useState } from 'react'
import {
  styled,
  LinkStyle,
  Grid,
  Link
} from '@pergas-common/pergas-components'
import {
  Company,
  Remove,
  OpenExternal
} from '@pergas-common/pergas-icons'

import { Link as RLink } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { EditContactRole } from './dialogs/EditContactRole'

const ReactRouterLink = styled(RLink)`
  ${LinkStyle};
`

const items = ({ children }) => {
  const { values, setFieldValue } = useFormikContext()
  const [edit, setEdit] = useState(null)

  const onDelete = useCallback((selected) => {
    const containing = Array.isArray(values.collection_role) ? [...values.collection_role] : []
    const index = containing.findIndex((c) => c.collection_id === selected.collection_id && c.role_id === selected.role_id)
    if (index > -1) {
      containing[index] = selected
      containing.splice(index, 1)
      setFieldValue('collection_role', containing)
    }
  }, [setFieldValue, values.collection_role])

  const results = values?.collection_role || []
  const withoutResponsible = results.filter((r) => r.role_internal_name !== 'responsible')

  const contactRoleRows = withoutResponsible.map((c) => {
    return (
      <Fragment key={`${c.collection_id}_${c.role_name}_${c.role_id}`}>
        <Grid.Item><Company width={18} height={18} /><span><ReactRouterLink to={`/contacts/edit?id=${c.collection_id}`}>{c.collection_name}</ReactRouterLink></span></Grid.Item>
        <Grid.Item><span>{c.role_name}</span></Grid.Item>
        <Grid.Item><span /></Grid.Item>
        <Grid.Item actions>
          <span />
          <span />
          <span><Link onClickHandler={() => { setEdit(c) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link></span>
          <span>{onDelete && <Link onClickHandler={() => { onDelete(c) }}><Remove width={20} height={20} color='#FF5656' /></Link>}</span>
        </Grid.Item>
      </Fragment>

    )
  })

  return (
    <>
      {!!edit && (
        <EditContactRole
          isOpen={!!edit}
          selected={edit}
          onCloseRequest={() => {
            setEdit(null)
          }}
        />
      )}
      <Grid.Content columns='2fr 2fr 0fr 1fr'>{contactRoleRows}</Grid.Content>
      {children}
    </>
  )
}

export const ContactRoleGrid = memo(items)
