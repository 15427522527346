import { TENANT_GET_OK_ACTION } from './actions.js'

/**
 * Reducer for handling tenants.
 */
export function tenant (state = { items: [] }, action) { // eslint-disable-line
  switch (action.type) {
    case TENANT_GET_OK_ACTION:
      return Object.assign({}, state, {
        items: action.items.slice()
      })
    default:
      return state
  }
}
