import { createSelector } from '@reduxjs/toolkit'

export const selectStatusByRequestId = createSelector(
  (state) => state.busy,
  (_, requestId) => requestId,
  (busy, requestId) => {
    return busy.requests[requestId]?.metadata?.status
  }
)
export const makeSelectMetadataByRequestId = () => createSelector(
  (state) => state.busy,
  (_, requestId) => requestId,
  (busy, requestId) => {
    return busy.requests[requestId]?.metadata
  }
)
