import { useEffect, useRef } from 'react'

/*
    Example usage together with useRequest hook:
        import useRequest from '../../hooks/useRequest';
        const [query] = useRequest(api.getTags)
        const [debouncedRequest] = useDebounce(query, 300)
*/
const useDebounce = (fn, delay) => {
  const timeout = useRef()
  const debouncedRequest = (query) => {
    timeout.current && clearTimeout(timeout.current)
    timeout.current = setTimeout(() => fn(query), delay)
  }

  useEffect(() => () => clearTimeout(timeout.current), [])
  return [debouncedRequest]
}

export default useDebounce
