import React from 'react'
import { TextField as MuiTextField } from '@mui/material'

/**
 * Wrapper around TextField with common props set
 */
const TextField = (props) => {
  return (
    <MuiTextField
      margin='dense'
      variant='outlined'
      fullWidth
      InputLabelProps={{ shrink: true }}
      {...props}
    >
      {props.children}
    </MuiTextField>
  )
}

export default TextField
