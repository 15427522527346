import React from 'react'
import { Button, List } from '@mui/material'
import DialogBase, { actionButtonStyle, actionIconStyle } from './DialogBase'
import { Close } from '@mui/icons-material'
import { AddItemIcon } from '../icons'

export default class AddBase extends DialogBase {
  constructor (props) {
    super(props)
    this.title = { Icon: AddItemIcon, IconColor: 'white' }
  }

  renderActions () {
    const { strings, onCancel } = this.props
    return (
      <>
        <Button
          onClick={onCancel}
          style={actionButtonStyle()}
        >
          <Close style={actionIconStyle} />{strings.cancel}
        </Button>
      </>
    )
  }

  renderContent () {
    return (
      <List>{this.createList()}</List>
    )
  }
}
