import { createSelector } from '@reduxjs/toolkit'

export const makeSelectorByIsFavorite = () => createSelector(
  (state) => state.project,
  (_, id) => id,
  (slice, id) => {
    const project = slice.items.find(({ id: _id }) => _id === Number(id))
    return project?.is_favorite
  }
)

export const makeSelectorBySharepointStatus = () => createSelector(
  (state) => state.project,
  (_, id) => id,
  (slice, id) => {
    const item = slice.items.find((item) => item.id === Number(id))
    return item && item.sharepoint_status
  }
)

export const makeSelectorBySharepointUrl = () => createSelector(
  (state) => state.project,
  (_, id) => id,
  (slice, id) => {
    const item = slice.items.find((item) => item.id === Number(id))
    return item && item.url
  }
)

export const makeSelectorById = () => createSelector(
  (state) => state.project,
  (_, id) => id,
  (slice, id) => {
    return slice.items.find(({ id: _id }) => _id === Number(id))
  }
)

export const makeSelectorProject = () => createSelector(
  (state) => state.project,
  (slice) => {
    return slice.items || []
  }
)

export const makeSelectorSelectedItemId = () => createSelector(
  (state) => state.project,
  (slice) => {
    return slice.selectedItemId
  }
)

export const makeSelectorEidById = () => createSelector(
  (state) => state.project,
  (_, id) => id,
  (slice, id) => {
    const project = slice.items?.find(({ id: _id }) => _id === Number(id))
    return project?.eid
  }
)
