import baseStyled, { createGlobalStyle, css } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"';
  }
`

export const breakpoints = {
  small: 600,
  medium: 1024,
  large: 1440,
  xlarge: 1920
}

// Iterate through the sizes and create a media template
export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (first, ...interpolations) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(first, ...interpolations)}
    }
  `

  return acc
}, {})

export const spacing = (m) => {
  return css`
    ${8 * m}px
  `
}

export const theme = {
  breakpoints,
  radius: 2 | 4 | 99999,
  zIndex: {
    dropdown: 1000
  },
  palette: {
    common: {
      primary: '#FFFFFF',
      secondary: '#000000'
    },
    brand: {
      primary: '#206a85',
      secondary: '#3a4a54'
    },
    border: {
      primary: 'rgba(0, 0, 0, 0.23)',
      secondary: '#2196f3',
      tertiary: 'rgba(0, 0, 0, 0.12)'
    },
    table: {
      primary: '#3a4a54',
      secondary: '#f7f9fa',
      tertiary: '#dee4ea'
    },
    button: {
      primary: 'none',
      secondary: '#28afe0'
    },
    link: {
      primary: '#28afe0'
    },
    tag: {
      primary: '#3a4a54'
    },
    disabled: {
      primary: 'rgba(0, 0, 0, 0.23)',
      secondary: '#2196f3',
      tertiary: 'rgba(0, 0, 0, 0.03)'
    }
  },
  typography: {
    title: {
      fontSize: '18px',
      fontWeight: 700
    },
    title2: {
      fontSize: '16px',
      fontWeight: 700
    },
    subTitle: {
      fontSize: '14px',
      fontWeight: 700
    },
    breadcrumb: {
      fontSize: '12px',
      fontWeight: 400
    },
    body: {
      fontSize: '14px',
      fontWeight: 400
    }
  },
  animated: {
    hover: 'rgba(0, 0, 0, 0.04)',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  }
}

export const styled = baseStyled
export default styled
