import React from 'react'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField
} from '@mui/material'
import {
  // TODO What to use here for generic Person
  // instead of AssignmentInd?
  AssignmentInd,
  Close,
  Save
} from '@mui/icons-material'
import { AddItemIcon, DeleteItemIcon } from '../icons'
import DialogBase, {
  actionButtonStyle,
  actionIconStyle
} from './DialogBase'
import AddPersonRole from './AddPersonRole'
import ColoredTag from '../ColoredTag'
import AddTag from './AddTag'
import { sortArray } from '../../util'
import {
  listItemStyle,
  listItemTextStyle
} from '../style'

const inputLabelProps = { shrink: true }

export default class EditBase extends DialogBase {
  constructor (props) {
    super(props)
    const { strings } = this.props
    this.OkIcon = Save
    this.okButtonText = strings.save
  }

  renderAddress () {
    const { strings } = this.props
    const { address = {} } = this.state.item

    const handleChange = (prop) => {
      return (event) => {
        const { value } = event.target
        const { item } = this.state
        if (!item.address) {
          item.address = {}
        }
        const { address } = item
        address[prop] = value
        this.setState({ item })
      }
    }

    return (
      <>
        <TextField
          size='small'
          margin='dense'
          variant='outlined'
          label={strings.address}
          value={address.address || ''}
          fullWidth
          onChange={handleChange('address')}
          InputLabelProps={inputLabelProps}
        />
        <TextField
          size='small'
          style={{ width: '30%', float: 'left' }}
          margin='dense'
          variant='outlined'
          label={strings.postal_code}
          value={address.postal_code || ''}
          onChange={handleChange('postal_code')}
          InputLabelProps={inputLabelProps}
        />
        <TextField
          size='small'
          style={{ width: '68%', float: 'left', marginLeft: '2%' }}
          margin='dense'
          variant='outlined'
          label={strings.city}
          value={address.city || ''}
          onChange={handleChange('city')}
          InputLabelProps={inputLabelProps}
        />
        <TextField
          size='small'
          margin='dense'
          variant='outlined'
          label={strings.email}
          value={address.email || ''}
          fullWidth
          onChange={handleChange('email')}
          InputLabelProps={inputLabelProps}
        />
        <TextField
          size='small'
          margin='dense'
          variant='outlined'
          label={strings.homepage}
          value={address.homepage || ''}
          fullWidth
          onChange={handleChange('homepage')}
          InputLabelProps={inputLabelProps}
        />
        <TextField
          size='small'
          margin='dense'
          variant='outlined'
          label={strings.phone}
          value={address.phone || ''}
          fullWidth
          onChange={handleChange('phone')}
          InputLabelProps={inputLabelProps}
        />
      </>
    )
  }

  renderPersonRole () {
    const { strings } = this.props
    const personRole = this.state.item.person_role

    const handleDelete = (pr) => {
      const { item } = this.state
      const copy = item.person_role.slice(0)
      const index = copy.findIndex(r => r.person_id === pr.person_id)
      if (index !== -1) {
        copy.splice(index, 1)
      }
      item.person_role = copy
      this.setState({ item })
    }

    const handleAdd = () => {
      this.setState({ showAddPersonRoleDialog: true })
    }

    const list = sortArray(personRole, 'person_name').map(r => {
      const key = `${r.person_id}:${r.role_id}`
      return (
        <ListItem style={listItemStyle} key={key}>
          <AssignmentInd style={{ marginRight: '10px' }} />
          <ListItemText
            primary={<><span>{r.person_name}</span><br /><span>({r.role_name})</span></>}
          />
          <ListItemSecondaryAction>
            <IconButton onClick={handleDelete.bind(null, r)} edge='end' aria-label='Delete'>
              <DeleteItemIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )
    })

    const subheader = (
      <ListSubheader component='div'>
        <ListItemText style={listItemTextStyle} primary={strings.person_role_add} />
        <ListItemSecondaryAction>
          <IconButton onClick={handleAdd} edge='end' aria-label='Add'>
            <AddItemIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListSubheader>
    )

    return (<List subheader={subheader}>{list}</List>)
  }

  renderAddPersonRole () {
    const { item, showAddPersonRoleDialog } = this.state
    if (!showAddPersonRoleDialog) return null

    const onOk = (pr) => {
      const { item } = this.state
      const copy = item.person_role.slice(0)
      copy.push(pr)
      item.person_role = copy
      this.setState({ item, showAddPersonRoleDialog: false })
    }

    const onCancel = () => {
      this.setState({ showAddPersonRoleDialog: false })
    }

    return (
      <AddPersonRole
        onOk={onOk}
        onCancel={onCancel}
        personRole={item.person_role}
      />
    )
  }

  renderTags () {
    const { strings, allTags } = this.props
    const { tags } = this.state.item

    const handleDelete = (tag) => {
      const { item } = this.state
      const { tags } = item
      const index = tags.findIndex(t => t.tag_id === tag.tag_id)
      if (index !== -1) {
        tags.splice(index, 1)
      }
      this.setState({ item })
    }

    const handleAdd = () => {
      this.setState({ showAddTagDialog: true })
    }

    const list = sortArray(tags, 'name').map(tag => {
      return (
        <ListItem style={listItemStyle} key={tag.tag_id}>
          <ColoredTag name={tag.name} color={tag.color} />
          <ListItemSecondaryAction>
            <IconButton onClick={handleDelete.bind(null, tag)} edge='end' aria-label='Delete'>
              <DeleteItemIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )
    })

    const disableAdd = allTags.every(tag => {
      return !!tags.find(t => t.tag_id === tag.id)
    })

    const subheader = (
      <ListSubheader component='div'>
        <ListItemText style={listItemTextStyle} primary={strings.add_tags} />
        <ListItemSecondaryAction>
          <IconButton disabled={disableAdd} onClick={handleAdd} edge='end' aria-label='Add'>
            <AddItemIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListSubheader>
    )

    return (<List subheader={subheader}>{list}</List>)
  }

  renderObjectTypeList () {
    const types = sortArray(this.props.objectTypes, 'name')
    return types.map(item => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    )).concat(<MenuItem key='0' value={0}>---</MenuItem>)
  }

  renderAddTag () {
    const { allTags } = this.props
    const { item, showAddTagDialog } = this.state
    const { tags } = item

    if (!showAddTagDialog) return null

    const onSelect = (tag) => {
      const { item } = this.state
      const tags = item.tags.slice(0)
      tags.push({
        tag_id: tag.id,
        name: tag.name,
        color: tag.color,
        tag_type_name: tag.tag_type_name
      })
      item.tags = tags
      this.setState({
        item,
        showAddTagDialog: false
      })
    }

    const onCancel = () => {
      this.setState({ showAddTagDialog: false })
    }

    const items = allTags.filter(tag => {
      return !tags.find(t => t.tag_id === tag.id)
    })

    return (
      <AddTag
        items={items}
        onSelect={onSelect}
        onCancel={onCancel}
      />
    )
  }

  renderActions () {
    const { strings, onOk, onCancel } = this.props
    const { item, saveDisabled } = this.state

    return (
      <>
        <Button
          disabled={saveDisabled}
          onClick={onOk.bind(null, item)}
          style={actionButtonStyle(saveDisabled)}
        >
          <Save style={actionIconStyle} />{strings.save}
        </Button>
        <Button
          onClick={onCancel}
          style={actionButtonStyle()}
        >
          <Close style={actionIconStyle} />{strings.cancel}
        </Button>
      </>
    )
  }
}
