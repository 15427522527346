import React from 'react'
import { connect } from 'react-redux'
import { TextField } from '@mui/material'
import EditProjectBase from './EditProjectBase'
import { ProjectIcon } from '../icons'
import redux from '../../redux/index.js'

class EditProject extends EditProjectBase {
  renderProject () {
    const {
      strings,
      objectTypes,
      collections
    } = this.props
    const { item } = this.state

    const handleChange = (prop) => {
      return (event) => {
        const { value } = event.target
        const { item } = this.state
        item[prop] = value
        this.setState({
          item,
          saveDisabled: this.isSaveDisabled(item)
        })
      }
    }

    return (
      <>
        <TextField
          size='small'
          margin='dense'
          variant='outlined'
          label={strings.name}
          value={item.name}
          fullWidth
          onChange={handleChange('name')}
          InputLabelProps={inputLabelProps}
          autoFocus
          required
        />
        <TextField
          size='small'
          select
          label={strings.contact}
          value={collections.length > 0 ? (item.collection_id || 0) : 0}
          style={selectStyle}
          onChange={handleChange('collection_id')}
          margin='dense'
          variant='outlined'
          required
        >
          {this.renderCollectionList()}
        </TextField>
        <TextField
          size='small'
          select
          label={strings.object_type}
          value={objectTypes.length > 0 ? (item.object_type_id || 0) : 0}
          style={selectStyle}
          onChange={handleChange('object_type_id')}
          margin='dense'
          variant='outlined'
        >
          {this.renderObjectTypeList()}
        </TextField>
        <TextField
          size='small'
          margin='dense'
          variant='outlined'
          label={strings.url}
          value={item.url || ''}
          fullWidth
          onChange={handleChange('url')}
          InputLabelProps={inputLabelProps}
        />
        <TextField
          size='small'
          margin='dense'
          variant='outlined'
          label={strings.teams_url}
          value={item.teams_url || ''}
          fullWidth
          onChange={handleChange('teams_url')}
          InputLabelProps={inputLabelProps}
        />
      </>
    )
  }
}

const inputLabelProps = { shrink: true }
const selectStyle = { width: '100%' }

const mapStateToProps = (state) => {
  const strings = state.locale.strings
  return {
    allTags: state.tag.items.filter(t => t.tag_type_name === 'project'),
    collections: state.contact.items,
    objectTypes: state.objectType.items.filter(t => t.object_type_type === 'project'),
    textTitle: strings.project,
    TitleIcon: ProjectIcon,
    strings
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions } = redux
  return {
    getData: () => {
      dispatch(actions.contact.getContacts())
      dispatch(actions.objectType.getObjectTypes())
      dispatch(actions.tag.getTags())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProject)
