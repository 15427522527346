import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { DIALOG_BG_COLOR } from '../style.js'

const titleStyle = {
  background: DIALOG_BG_COLOR,
  color: 'white',
  padding: '12px 19px',
  fontSize: '20px'
}

const titleIconStyle = {
  transform: 'translate(0px, 4px)',
  marginRight: '12px'
}

const titleCloseStyle = {
  transform: 'translate(0px, 4px)',
  float: 'right',
  cursor: 'pointer'
}

const contentStyle = {
  padding: '15px 25px'
}

const actionsStyle = {
  borderTop: '1px solid #707070',
  background: DIALOG_BG_COLOR
}

export const actionButtonStyle = (disabled = false) => {
  return {
    color: disabled ? '#aaafb2' : '#ffffff',
    textTransform: 'none'
  }
}

export const actionIconStyle = {
  marginRight: '5px'
}

export default class DialogBase extends React.Component {
  renderTitle () {
    const { onCancel } = this.props
    const { text, Icon, IconColor } = this.title
    const iconStyle = Object.assign(
      {},
      titleIconStyle,
      IconColor ? { color: IconColor } : {}
    )
    return (
      <DialogTitle style={titleStyle}>
        <Icon style={iconStyle} />
        {text}
        <Close style={titleCloseStyle} onClick={onCancel} />
      </DialogTitle>
    )
  }

  render () {
    const { onCancel } = this.props
    return (
      <Dialog open maxWidth={this.maxWidth || 'md'} onClose={onCancel}>
        {this.renderTitle()}
        <DialogContent style={contentStyle}>
          {this.renderContent()}
        </DialogContent>
        <DialogActions style={actionsStyle}>
          {this.renderActions()}
        </DialogActions>
      </Dialog>
    )
  }
}
