import { createSlice } from '@reduxjs/toolkit'

/**
 * Reducer for busy state
 */
const initialState = {
  count: 0,
  requests: {}
}

export const busySlice = createSlice({
  name: 'busy',
  initialState,
  reducers: {
    busy: (state, action) => {
      state.count += 1
      if (action.payload) {
        state.requests[action.payload.requestId] = {
          metadata: {
            id: action.payload?.id,
            status: 'pending'
          }
        }
      }
    },
    notBusy: (state, action) => {
      state.count -= 1
      if (action.payload) {
        state.requests[action.payload.requestId] = {
          metadata: {
            id: action.payload?.id,
            status: 'resolved'
          }
        }
      }
    },
    errBusy: (state, action) => {
      state.count -= 1
      if (action.payload) {
        state.requests[action.payload.requestId] = {
          metadata: {
            id: action.payload?.id,
            status: 'rejected'
          }
        }
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { busy, notBusy, errBusy } = busySlice.actions

export default busySlice.reducer
