import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const usePermissionRoute = (permissions, keys = []) => {
  const navigate = useNavigate()
  useEffect(() => {
    keys.forEach((key) => {
      if (!permissions[key]) {
        navigate(-1)
      }
    })
  }, [navigate, permissions, keys])
}
