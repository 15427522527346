import { pageDefault } from '../common.js'
import {
  PROJECT_CREATE_ACTION,
  PROJECT_SHOW_ADD_DIALOG_ACTION,
  PROJECT_HIDE_ADD_DIALOG_ACTION,
  PROJECT_GET_OK_ACTION,
  PROJECT_UPDATE_ACTION,
  PROJECT_SHOW_EDIT_DIALOG_ACTION,
  PROJECT_HIDE_EDIT_DIALOG_ACTION,
  PROJECT_DELETE_ACTION,
  PROJECT_SHOW_DELETE_DIALOG_ACTION,
  PROJECT_HIDE_DELETE_DIALOG_ACTION,

  PROJECT_GET_PAGE_ITEMS_ACTION,
  PROJECT_SET_ORDER_ACTION,
  PROJECT_SET_LIMIT_ACTION,
  PROJECT_SET_OFFSET_ACTION,
  PROJECT_SET_SEARCH_ACTION,
  PROJECT_RESET_SEARCH_ACTION,
  PROJECT_SET_FILTER_QUERIES_ACTION,

  PROJECT_SET_SELECTED_ITEM_ID
} from './actions.js'

const initialState = pageDefault('name', 'project', {
  filterQueries: [{ key: 'ended_at', value: null, op: '=' }]
})

/**
 * Reducer for handling projects.
 */
export function project (state = initialState, action) {
  switch (action.type) {
    case PROJECT_GET_OK_ACTION:
      return { ...state, items: action.items.slice() }
    case PROJECT_CREATE_ACTION:
      return {
        ...state,
        items: state.items.concat(action.project),
        pageItems: [action.project].concat(state.pageItems)
      }
    case PROJECT_SHOW_ADD_DIALOG_ACTION:
      return { ...state, addItem: true }
    case PROJECT_HIDE_ADD_DIALOG_ACTION:
      return { ...state, addItem: false }
    case PROJECT_UPDATE_ACTION:
      return {
        ...state,
        items: state.items.map(c => {
          return c.id === action.project.id ? action.project : c
        }),
        pageItems: state.pageItems.map(c => {
          if (c.id === action.project.id) {
            return action.project
          }
          return c
        })
      }
    case PROJECT_SHOW_EDIT_DIALOG_ACTION:
      return { ...state, editItem: action.project }
    case PROJECT_HIDE_EDIT_DIALOG_ACTION:
      return { ...state, editItem: null }
    case PROJECT_DELETE_ACTION:
      return {
        ...state,
        items: state.items.filter(c => c.id !== action.project.id),
        pageItems: state.pageItems.filter(c => c.id !== action.project.id)
      }
    case PROJECT_SHOW_DELETE_DIALOG_ACTION:
      return { ...state, deleteItem: action.project }
    case PROJECT_HIDE_DELETE_DIALOG_ACTION:
      return { ...state, deleteItem: null }

    case PROJECT_GET_PAGE_ITEMS_ACTION:
      return { ...state, pageItems: action.pageItems }
    case PROJECT_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return { ...state, order, orderBy, offset: 0 }
    }
    case PROJECT_SET_LIMIT_ACTION:
      return { ...state, limit: action.limit, offset: 0 }
    case PROJECT_SET_OFFSET_ACTION:
      return { ...state, offset: action.offset }
    case PROJECT_SET_SEARCH_ACTION:
      return { ...state, search: action.search, offset: 0 }
    case PROJECT_RESET_SEARCH_ACTION:
      return { ...state, search: '', offset: 0 }
    case PROJECT_SET_FILTER_QUERIES_ACTION:
      return { ...state, filterQueries: action.filterQueries, offset: 0 }

    case PROJECT_SET_SELECTED_ITEM_ID:
      return { ...state, selectedItemId: action.selectedItemId }

    default:
      return state
  }
}
