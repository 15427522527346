import React from 'react'
import { MenuItem } from '@mui/material'
import EditBase from './EditBase'
import { sortArray } from '../../util'

export default class EditProjectBase extends EditBase {
  constructor (props) {
    super(props)

    const { textTitle, TitleIcon } = this.props
    this.title = { text: textTitle, Icon: TitleIcon }

    const item = Object.assign({
      name: '',
      collection_id: null,
      object_type_id: null,
      collection: {
        id: 0,
        name: ''
      },
      url: '',
      teams_url: '',
      person_role: [],
      tags: []
    }, props.item)

    item.person_role = item.person_role || []
    item.tags = item.tags || []

    const saveDisabled = this.isSaveDisabled(item)
    this.state = {
      item,
      saveDisabled,
      showAddPersonRoleDialog: false,
      showAddTagDialog: false
    }
  }

  componentDidMount () {
    this.props.getData()
  }

  isSaveDisabled (item) {
    return item.name.length === 0 || !item.collection_id
  }

  renderCollectionList () {
    const collections = sortArray(this.props.collections, 'name')
    return collections.map(c => (
      <MenuItem key={c.id} value={c.id}>
        {c.name}
      </MenuItem>
    )).concat(<MenuItem key='0' value={0}>---</MenuItem>)
  }

  renderContent () {
    return (
      <>
        {this.renderAddPersonRole()}
        {this.renderAddTag()}
        <div style={columnStyle}>
          {this.renderProject()}
        </div>
        <div style={columnStyle}>
          {this.renderPersonRole()}
        </div>
        <div style={columnStyle}>
          {this.renderTags()}
        </div>
      </>
    )
  }
}

const columnStyle = {
  width: '33%',
  float: 'left'
}
