import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { MenuItem } from '@mui/material'
import EditDialog from './EditDialog.js'
import { ContactPersonIcon } from '../icons.js'
import { sortArray } from '../../util.js'
import TextField from './TextField.js'
import TagList from './TagList.js'
import AddTag from './AddTag.js'
import redux from '../../redux/index.js'

const EditContactPerson = ({
  item,
  strings,
  allTags,
  collections,
  objectTypes,
  getData,
  onOk,
  onCancel
}) => {
  useEffect(getData, [])

  const contactPerson = Object.assign({
    id: 0,
    first_name: '',
    last_name: '',
    collection_id: 0,
    object_type_id: 0,
    address: {
      address: '',
      postal_code: '',
      city: '',
      email: '',
      homepage: '',
      phone: ''
    },
    tags: []
  }, item)

  contactPerson.tags = contactPerson.tags || []

  const [firstName, setFirstName] = useState(contactPerson.first_name || '')
  const [lastName, setLastName] = useState(contactPerson.last_name || '')
  const [
    collectionId,
    setCollectionId
  ] = useState(contactPerson.collection_id || 0)
  const [
    objectTypeId,
    setObjectTypeId
  ] = useState(contactPerson.object_type_id || 0)

  const [address, setAddress] = useState(contactPerson?.address?.address || '')
  const [postalCode, setPostalCode] = useState(contactPerson?.address?.postal_code || '')
  const [city, setCity] = useState(contactPerson?.address?.city || '')
  const [email, setEmail] = useState(contactPerson?.address?.email || '')
  const [homepage, setHomepage] = useState(contactPerson?.address?.homepage || '')
  const [phone, setPhone] = useState(contactPerson?.address?.phone || '')

  const [tags, setTags] = useState(contactPerson.tags)
  const [
    showAddTagDialog,
    setShowAddTagDialog
  ] = useState(false)

  const localOnOk = () => {
    onOk({
      id: contactPerson.id,
      first_name: firstName,
      last_name: lastName,
      collection_id: collectionId,
      object_type_id: objectTypeId,
      address: {
        address,
        postal_code: postalCode,
        city,
        email,
        homepage,
        phone
      },
      tags
    })
  }

  const saveDisabled = () => firstName.length === 0 || lastName.length === 0

  const renderAddTag = () => {
    if (!showAddTagDialog) return null

    const onSelect = (tag) => {
      const copy = tags.slice(0)
      copy.push({
        tag_id: tag.id,
        name: tag.name,
        color: tag.color,
        tag_type_name: tag.tag_type_name
      })
      setTags(copy)
      setShowAddTagDialog(false)
    }

    const onCancel = () => setShowAddTagDialog(false)

    const items = allTags.filter(tag => {
      return !tags.find(t => t.tag_id === tag.id)
    })

    return (
      <AddTag
        items={items}
        onSelect={onSelect}
        onCancel={onCancel}
      />
    )
  }

  const renderCollectionList = () => {
    return sortArray(collections, 'name').map(c => (
      <MenuItem key={c.id} value={c.id}>
        {c.name}
      </MenuItem>
    )).concat(<MenuItem key='0' value={0}>---</MenuItem>)
  }

  const renderObjectTypeList = () => {
    return sortArray(objectTypes, 'name').map(type => (
      <MenuItem key={type.id} value={type.id}>
        {type.name}
      </MenuItem>
    )).concat(<MenuItem key='0' value={0}>---</MenuItem>)
  }

  const selectClient = (id) => {
    const contact = collections.find(c => c.id === id)
    setCollectionId(id)
    setAddress(contact?.address?.address || '')
    setPostalCode(contact?.address?.postal_code || '')
    setCity(contact?.address?.city || '')
    setHomepage(contact?.address?.homepage || '')
  }

  return (
    <EditDialog
      titleText={strings.contact_person}
      TitleIcon={ContactPersonIcon}
      onOk={localOnOk}
      onCancel={onCancel}
      saveDisabled={saveDisabled()}
    >
      {renderAddTag()}
      <div style={columnStyle}>
        <TextField
          size='small'
          required
          label={strings.first_name}
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          autoFocus
        />
        <TextField
          size='small'
          required
          label={strings.last_name}
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
        />
        <TextField
          size='small'
          select
          label={strings.contact}
          value={collections.length > 0 ? collectionId : 0}
          onChange={(event) => selectClient(event.target.value)}
        >
          {renderCollectionList()}
        </TextField>
        <TextField
          size='small'
          select
          label={strings.object_type}
          value={objectTypes.length > 0 ? objectTypeId : 0}
          onChange={(event) => setObjectTypeId(event.target.value)}
        >
          {renderObjectTypeList()}
        </TextField>
        <TextField
          size='small'
          label={strings.address}
          value={address}
          onChange={(event) => setAddress(event.target.value)}
        />
        <TextField
          size='small'
          label={strings.postal_code}
          value={postalCode}
          onChange={(event) => setPostalCode(event.target.value)}
          style={{ width: '30%', float: 'left' }}
        />
        <TextField
          size='small'
          label={strings.city}
          value={city || ''}
          onChange={(event) => setCity(event.target.value)}
          style={{ width: '68%', float: 'left', marginLeft: '2%' }}
        />
        <TextField
          size='small'
          label={strings.email}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          size='small'
          label={strings.homepage}
          value={homepage}
          onChange={(event) => setHomepage(event.target.value)}
        />
        <TextField
          size='small'
          label={strings.phone}
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
        />
      </div>
      <div style={columnStyle}>
        <TagList
          items={tags}
          setTags={setTags}
          onAddClick={() => setShowAddTagDialog(true)}
          allTags={allTags}
        />
      </div>
    </EditDialog>
  )
}

const columnStyle = {
  width: '50%',
  float: 'left'
}

const mapStateToProps = (state) => {
  const strings = state.locale.strings
  return {
    allTags: state.tag.items.filter(t => t.tag_type_name === 'contact_person'),
    strings,
    collections: state.contact.items,
    objectTypes: state.objectType.items.filter(t => t.object_type_type === 'contact_person')
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions } = redux
  return {
    getData: () => {
      dispatch(actions.contact.getContacts())
      dispatch(actions.objectType.getObjectTypes())
      dispatch(actions.tag.getTags())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditContactPerson)
