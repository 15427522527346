import React from 'react'
import { useSelector } from 'react-redux'
import { styled, Button, Group } from '@pergas-common/pergas-components'
import { selectLocale } from '../../redux/locale/selectors'
import { Close } from '@pergas-common/pergas-icons'

const Holder = styled.div`
  display: flex;
  height: 130px;
  flex-direction: column;
`

const ContentHolder = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  align-self: center;
  > span {
    ${({ theme }) => theme.typography.body};
    color: ${({ theme }) => theme.palette.brand.secondary};
  }
`

export const Footer = ({ confirmLabel, confirmIcon, onConfirm, onCloseRequest }) => {
  const locale = useSelector(selectLocale)
  return (
    <Group.Button>
      {onCloseRequest && <Button onClick={onCloseRequest}><span>{locale.cancel}</span><Close width={18} height={18} color='#28afe0' /></Button>}
      {onConfirm && <Button onClick={onConfirm}><span>{confirmLabel}</span>{confirmIcon}</Button>}
    </Group.Button>
  )
}

export const Body = ({ children }) => {
  return (
    <Holder><ContentHolder>{children}</ContentHolder></Holder>
  )
}
