import React from 'react'
import { unparse } from 'papaparse'
import { Link } from '@pergas-common/pergas-components'
import { Excel } from '@pergas-common/pergas-icons'

const CsvButton = ({ download = '', keys, data }) => {
  return (
    <Link onClickHandler={() => {
      const str = unparse({
        fields: keys,
        data
      }, {
        encoding: 'ISO-8859-1'
      })
      const ourl = URL.createObjectURL(new Blob(['\ufeff', str]), { type: 'text/csv' })
      const t = document.createElement('a')
      document.body.appendChild(t)
      t.href = ourl
      t.download = `${download}.csv`
      t.click()
      document.body.removeChild(t)
    }}
    ><Excel width={18} height={18} />
    </Link>
  )
}

export default CsvButton
