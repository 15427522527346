import { pageDefault } from '../common.js'
import {
  ACCOUNT_CREATE_ACTION,
  ACCOUNT_SHOW_ADD_DIALOG_ACTION,
  ACCOUNT_HIDE_ADD_DIALOG_ACTION,
  ACCOUNT_GET_OK_ACTION,
  ACCOUNT_UPDATE_ACTION,
  ACCOUNT_SHOW_EDIT_DIALOG_ACTION,
  ACCOUNT_HIDE_EDIT_DIALOG_ACTION,
  ACCOUNT_DELETE_ACTION,
  ACCOUNT_SHOW_DELETE_DIALOG_ACTION,
  ACCOUNT_HIDE_DELETE_DIALOG_ACTION,

  ACCOUNT_GET_PAGE_ITEMS_ACTION,
  ACCOUNT_SET_ORDER_ACTION,
  ACCOUNT_SET_LIMIT_ACTION,
  ACCOUNT_SET_OFFSET_ACTION,
  ACCOUNT_SET_SEARCH_ACTION,
  ACCOUNT_RESET_SEARCH_ACTION
} from './actions.js'

const initialState = pageDefault('name', 'account')

/**
 * Reducer for handling accounts.
 */
export function account (state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_GET_OK_ACTION:
      return Object.assign({}, state, {
        items: action.items.slice()
      })
    case ACCOUNT_CREATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.concat(action.account),
        pageItems: [action.account].concat(state.pageItems)
      })
    case ACCOUNT_SHOW_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: true
      })
    case ACCOUNT_HIDE_ADD_DIALOG_ACTION:
      return Object.assign({}, state, {
        addItem: false
      })
    case ACCOUNT_UPDATE_ACTION:
      return Object.assign({}, state, {
        items: state.items.map(a => a.id === action.account.id ? action.account : a),
        pageItems: state.pageItems.map(a => a.id === action.account.id ? action.account : a)
      })
    case ACCOUNT_SHOW_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: action.account
      })
    case ACCOUNT_HIDE_EDIT_DIALOG_ACTION:
      return Object.assign({}, state, {
        editItem: null
      })
    case ACCOUNT_DELETE_ACTION:
      return Object.assign({}, state, {
        items: state.items.filter(a => a.id !== action.account.id),
        pageItems: state.pageItems.filter(a => a.id !== action.account.id)
      })
    case ACCOUNT_SHOW_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: action.account
      })
    case ACCOUNT_HIDE_DELETE_DIALOG_ACTION:
      return Object.assign({}, state, {
        deleteItem: null
      })

    case ACCOUNT_GET_PAGE_ITEMS_ACTION:
      return Object.assign({}, state, { pageItems: action.pageItems })
    case ACCOUNT_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return Object.assign({}, state, { order, orderBy, offset: 0 })
    }
    case ACCOUNT_SET_LIMIT_ACTION:
      return Object.assign({}, state, { limit: action.limit, offset: 0 })
    case ACCOUNT_SET_OFFSET_ACTION:
      return Object.assign({}, state, { offset: action.offset })
    case ACCOUNT_SET_SEARCH_ACTION:
      return Object.assign({}, state, { search: action.search, offset: 0 })
    case ACCOUNT_RESET_SEARCH_ACTION:
      return Object.assign({}, state, { search: '', offset: 0 })

    default:
      return state
  }
}
