import React, { memo, Fragment } from 'react'
import { Link as RLink } from 'react-router-dom'
import {
  styled,
  Grid,
  LinkStyle,
  Link as PergasLink
} from '@pergas-common/pergas-components'
import {
  Contact,
  Teams
} from '@pergas-common/pergas-icons'
import { withDataGrid } from './GridHoc'
import { sharePointAccessor } from './pages/columns'

const ReactRouterLink = styled(RLink)`
  ${LinkStyle};
    display: initial;

`

const Strikethrough = styled.span`
  text-decoration: ${({ isDone }) => isDone ? 'line-through' : 'none'};
`

const items = ({ result }) => {
  const projectRow = Array.isArray(result)
    ? result.map((p) => {
        const responsible = Array.isArray(p.person_role)
          ? p.person_role.find((pr) => {
              return pr.role_internal_name === 'responsible'
            })
          : null
        return (
          <Fragment key={p.id}>
            <Grid.Item><Contact width={18} height={18} /><Strikethrough isDone={p.ended_at}><ReactRouterLink to={`/projects/edit?id=${p.id}`}>{p.name}</ReactRouterLink></Strikethrough></Grid.Item>
            <Grid.Item><span>{responsible && responsible.person_name}</span></Grid.Item>
            <Grid.Item actions>
              {!!p.url && <span>{sharePointAccessor({ url: p.url })}</span>}
              {!!p.teams_url && <span><PergasLink onClickHandler={(e) => { e.preventDefault() }} href={p.teams_url} icon={<Teams width={20} height={20} />} /></span>}
            </Grid.Item>
          </Fragment>
        )
      })
    : <></>

  return projectRow
}

export const ProjectGrid = memo(withDataGrid(items))
