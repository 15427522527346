import React from 'react'
import { connect } from 'react-redux'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from '@mui/material'
import {
  // TODO What to use here for generic Person
  // instead of AssignmentInd?
  AssignmentInd
} from '@mui/icons-material'
import { AddItemIcon, DeleteItemIcon } from '../icons'
import { sortArray } from '../../util'
import {
  listItemStyle,
  listItemTextStyle
} from '../style'

/**
 * Renders a list of person/role combinations.
 * The component has an add button which takes a handler.
 * Each row in the list has a delete button and will call
 * back with the updated array so the parent component can
 * update its state.
 */
const PersonRoleList = ({
  items,
  onAddClick,
  setPersonRole,
  strings
}) => {
  const handleDelete = (pr) => {
    const copy = items.slice(0)
    const index = copy.findIndex(r => r.person_id === pr.person_id)
    if (index !== -1) {
      copy.splice(index, 1)
    }
    setPersonRole(copy)
  }

  const list = sortArray(items, 'person_name').map(r => {
    const key = `${r.person_id}:${r.role_id}`
    return (
      <ListItem style={listItemStyle} key={key}>
        <AssignmentInd style={{ marginRight: '10px' }} />
        <ListItemText
          primary={<><span>{r.person_name}</span><br /><span>({r.role_name})</span></>}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={handleDelete.bind(null, r)} edge='end' aria-label='Delete'>
            <DeleteItemIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  })

  const subheader = (
    <ListSubheader component='div'>
      <ListItemText style={listItemTextStyle} primary={strings.person_role_add} />
      <ListItemSecondaryAction>
        <IconButton onClick={onAddClick} edge='end' aria-label='Add'>
          <AddItemIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListSubheader>
  )

  return (<List subheader={subheader}>{list}</List>)
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

export default connect(mapStateToProps)(PersonRoleList)
