import storage from '../storage'

export const CHANGE_LOCALE_ACTION = 'CHANGE_LOCALE_ACTION'

export function setLocale (locale) {
  storage.putLocale(locale)
  return {
    type: CHANGE_LOCALE_ACTION,
    locale
  }
}
