import React from 'react'
import { Link as RRLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { styled, LinkStyle } from '@pergas-common/pergas-components'
import { Back } from '@pergas-common/pergas-icons'
import { selectLocale } from '../../redux/locale/selectors'

const Holder = styled.div`    
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.typography.title};
  > div > span > svg { 
    vertical-align: bottom;
  }
`

const Link = styled(RRLink)`
  ${LinkStyle}
`

const NotFoundPage = () => {
  const locale = useSelector(selectLocale)
  return (
    <Holder>
      <div>
        <h1>{locale.missing_content_page}</h1>
        <span><Back width={20} height={20} /> <Link to='/'>{locale.back}</Link> </span>
      </div>
    </Holder>
  )
}

export default NotFoundPage
