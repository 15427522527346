import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { Close, Save } from '@mui/icons-material'
import { DIALOG_BG_COLOR } from '../style.js'
// TODO refactor, move these functions to style.js
import { actionButtonStyle, actionIconStyle } from './DialogBase'

const titleStyle = {
  background: DIALOG_BG_COLOR,
  color: 'white',
  padding: '12px 19px',
  fontSize: '20px'
}

const titleIconStyle = {
  transform: 'translate(0px, 4px)',
  marginRight: '12px',
  color: 'white'
}

const titleCloseStyle = {
  transform: 'translate(0px, 4px)',
  float: 'right',
  cursor: 'pointer'
}

const actionsStyle = {
  borderTop: '1px solid #707070',
  background: DIALOG_BG_COLOR
}

const contentStyle = {
  padding: '15px 25px'
}

const EditDialog = ({
  titleText,
  TitleIcon,
  strings,
  onOk,
  onCancel,
  item,
  saveDisabled,
  children
}) => {
  return (
    <Dialog open maxWidth='md' onClose={onCancel}>
      <DialogTitle style={titleStyle}>
        <TitleIcon style={titleIconStyle} />
        {titleText}
        <Close style={titleCloseStyle} onClick={onCancel} />
      </DialogTitle>
      <DialogContent style={contentStyle}>
        {children}
      </DialogContent>
      <DialogActions style={actionsStyle}>
        <Button
          disabled={saveDisabled}
          onClick={onOk.bind(null, item)}
          style={actionButtonStyle(saveDisabled)}
        >
          <Save style={actionIconStyle} />{strings.save}
        </Button>
        <Button
          onClick={onCancel}
          style={actionButtonStyle()}
        >
          <Close style={actionIconStyle} />{strings.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

export default connect(mapStateToProps)(EditDialog)
