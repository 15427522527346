import React from 'react'
import reactCSS from 'reactcss'
import { connect } from 'react-redux'
import SketchPicker from 'react-color'
import { Dialog, MenuItem, TextField } from '@mui/material'
import { TagIcon } from '../icons'
import { sortArray } from '../../util'
import EditBase from './EditBase'

const inputLabelProps = { shrink: true }

class EditTag extends EditBase {
  constructor (props) {
    super(props)

    const { strings } = this.props
    this.title = { text: strings.tag, Icon: TagIcon }

    const item = Object.assign({
      name: '',
      description: '',
      color: '#B80000',
      tag_type_name: '--'
    }, props.item)

    const saveDisabled = this.isSaveDisabled(item)
    this.state = {
      item,
      saveDisabled,
      displayColorPicker: false
    }

    this.handleColorPickerOpen = this.handleColorPickerOpen.bind(this)
    this.handleColorPickerClose = this.handleColorPickerClose.bind(this)
    this.handleColorPickerChange = this.handleColorPickerChange.bind(this)
  }

  handleColorPickerOpen () {
    this.setState({
      displayColorPicker:
      !this.state.displayColorPicker
    })
  }

  handleColorPickerClose () {
    this.setState({
      displayColorPicker: false
    })
  }

  handleColorPickerChange (color) {
    const { item } = this.state
    item.color = color.hex
    this.setState({ item })
  }

  isSaveDisabled (item) {
    return item.name.length === 0 || item.tag_type_name === '--'
  }

  handleChange (prop) {
    return (event) => {
      const { value } = event.target
      const { item } = this.state
      item[prop] = value
      const saveDisabled = this.isSaveDisabled(item)
      this.setState({ item, saveDisabled })
    }
  }

  renderColorPicker () {
    const { item } = this.state
    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '20px',
          borderRadius: '2px',
          background: item.color
        },
        swatch: {
          marginTop: '5px',
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          float: 'right'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }
      }
    })

    const renderColorPickerDialog = () => {
      if (this.state.displayColorPicker) {
        return (
          <Dialog open>
            <div style={styles.cover} onClick={this.handleColorPickerClose} />
            <SketchPicker color={item.color} onChange={this.handleColorPickerChange} />
          </Dialog>
        )
      } else {
        return null
      }
    }

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleColorPickerOpen}>
          <div style={styles.color} />
        </div>
        {renderColorPickerDialog()}
      </div>
    )
  }

  renderTagTypeList () {
    const { strings } = this.props

    const objectTypeTypes = sortArray([
      { value: '--', label: '--' },
      { value: 'contact', label: strings.contact },
      { value: 'department', label: strings.department },
      { value: 'project', label: strings.project },
      { value: 'category', label: strings.category },
      { value: 'contact_person', label: strings.contact_person },
      { value: 'employee', label: strings.employee },
      { value: 'ticket', label: strings.ticket }
    ], 'label')

    return objectTypeTypes.map((item, index) => (
      <MenuItem key={index} value={item.value}>
        {item.label}
      </MenuItem>
    ))
  }

  renderTagType () {
    const { strings, isEditing } = this.props
    const { item } = this.state

    if (isEditing) return null

    return (
      <TextField
        size='small'
        margin='dense'
        variant='outlined'
        fullWidth
        select
        label={strings.tag_type}
        value={item.tag_type_name}
        onChange={this.handleChange('tag_type_name')}
        InputLabelProps={inputLabelProps}
        required
      >
        {this.renderTagTypeList()}
      </TextField>
    )
  }

  renderContent () {
    const { strings } = this.props
    const { item } = this.state

    return (
      <>
        <TextField
          size='small'
          margin='dense'
          variant='outlined'
          label={strings.name}
          value={item.name || ''}
          fullWidth
          onChange={this.handleChange('name')}
          InputLabelProps={inputLabelProps}
          autoFocus
          required
        />
        <TextField
          size='small'
          margin='dense'
          variant='outlined'
          label={strings.description}
          value={item.description || ''}
          fullWidth
          onChange={this.handleChange('description')}
          InputLabelProps={inputLabelProps}
        />
        {this.renderTagType()}
        {this.renderColorPicker()}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

export default connect(mapStateToProps)(EditTag)
