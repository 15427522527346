import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { MenuItem } from '@mui/material'
import { DepartmentIcon } from '../icons'
import { sortArray } from '../../util'
import redux from '../../redux/index.js'
import EditDialog from './EditDialog'
import TextField from './TextField'
import PersonRoleList from './PersonRoleList'
import AddPersonRole from './AddPersonRole'
import TagList from './TagList'
import AddTag from './AddTag'

const EditDepartment = ({
  getData,
  strings,
  onOk,
  onCancel,
  item,
  objectTypes,
  allTags
}) => {
  useEffect(getData, [])

  const department = Object.assign({
    id: 0,
    name: '',
    url: '',
    teams_url: '',
    object_type_id: 0,
    person_role: [],
    tags: []
  }, item)

  department.person_role = department.person_role || []
  department.tags = department.tags || []

  const [name, setName] = useState(department.name)
  const url = department.url || ''
  const [teamsUrl, setTeamsUrl] = useState(department.teams_url || '')
  const [
    objectTypeId,
    setObjectTypeId
  ] = useState(department.object_type_id || 0)

  const [personRole, setPersonRole] = useState(department.person_role)
  const [
    showAddPersonRoleDialog,
    setShowAddPersonRoleDialog
  ] = useState(false)

  const [tags, setTags] = useState(department.tags)
  const [
    showAddTagDialog,
    setShowAddTagDialog
  ] = useState(false)

  const localOnOk = () => {
    onOk({
      id: department.id,
      name,
      url,
      teams_url: teamsUrl,
      object_type_id: objectTypeId,
      person_role: personRole,
      tags
    })
  }

  const saveDisabled = () => name.length === 0

  const renderObjectTypeList = () => {
    const types = sortArray(objectTypes, 'name')
    return types.map(type => (
      <MenuItem key={type.id} value={type.id}>
        {type.name}
      </MenuItem>
    )).concat(<MenuItem key='0' value={0}>---</MenuItem>)
  }

  // TODO refactor later. Maybe move to AddPersonRole component?
  // Need to rewrite all dialogs first.
  const renderAddPersonRole = () => {
    if (!showAddPersonRoleDialog) return null

    const onOk = (pr) => {
      const copy = personRole.slice(0)
      copy.push(pr)
      setPersonRole(copy)
      setShowAddPersonRoleDialog(false)
    }

    const onCancel = () => setShowAddPersonRoleDialog(false)

    return (
      <AddPersonRole
        onOk={onOk}
        onCancel={onCancel}
        personRole={personRole}
      />
    )
  }

  // TODO refactor, same pattern as for person/role above
  const renderAddTag = () => {
    if (!showAddTagDialog) return null

    const onSelect = (tag) => {
      const copy = tags.slice(0)
      copy.push({
        tag_id: tag.id,
        name: tag.name,
        color: tag.color,
        tag_type_name: tag.tag_type_name
      })
      setTags(copy)
      setShowAddTagDialog(false)
    }

    const onCancel = () => setShowAddTagDialog(false)

    const items = allTags.filter(tag => {
      return !tags.find(t => t.tag_id === tag.id)
    })

    return (
      <AddTag
        items={items}
        onSelect={onSelect}
        onCancel={onCancel}
      />
    )
  }

  return (
    <EditDialog
      titleText={strings.department}
      TitleIcon={DepartmentIcon}
      onOk={localOnOk}
      onCancel={onCancel}
      saveDisabled={saveDisabled()}
    >
      {renderAddPersonRole()}
      {renderAddTag()}
      <div style={columnStyle}>
        <TextField
          size='small'
          required
          label={strings.name}
          value={name}
          onChange={(event) => setName(event.target.value)}
          autoFocus
        />
        <TextField
          size='small'
          label={strings.teams_url}
          value={teamsUrl}
          onChange={(event) => setTeamsUrl(event.target.value)}
        />
        <TextField
          size='small'
          select
          label={strings.department_object_type}
          value={objectTypes.length > 0 ? objectTypeId : 0}
          onChange={(event) => setObjectTypeId(event.target.value)}
        >
          {renderObjectTypeList()}
        </TextField>
      </div>
      <div style={columnStyle}>
        <PersonRoleList
          items={personRole}
          setPersonRole={setPersonRole}
          onAddClick={() => setShowAddPersonRoleDialog(true)}
        />
      </div>
      <div style={columnStyle}>
        <TagList
          items={tags}
          setTags={setTags}
          onAddClick={() => setShowAddTagDialog(true)}
          allTags={allTags}
        />
      </div>
    </EditDialog>
  )
}

const columnStyle = {
  width: '33%',
  float: 'left'
}

const mapStateToProps = (state) => {
  return {
    allTags: state.tag.items.filter(t => t.tag_type_name === 'department'),
    strings: state.locale.strings,
    objectTypes: state.objectType.items.filter(t => t.object_type_type === 'department')
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions } = redux
  return {
    getData: () => {
      dispatch(actions.objectType.getObjectTypes())
      dispatch(actions.tag.getTags())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDepartment)
