import { styled } from '@pergas-common/pergas-components'
const Divider = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 320px;
  width: 100%;
  height 0.5px;
  background: ${({ theme }) => theme.palette.border.primary};
`

export default Divider
