import { pageDefault } from '../common.js'
import {
  CONTACT_PERSON_CREATE_ACTION,
  CONTACT_PERSON_SHOW_ADD_DIALOG_ACTION,
  CONTACT_PERSON_HIDE_ADD_DIALOG_ACTION,
  CONTACT_PERSON_GET_OK_ACTION,
  CONTACT_PERSON_UPDATE_ACTION,
  CONTACT_PERSON_SHOW_EDIT_DIALOG_ACTION,
  CONTACT_PERSON_HIDE_EDIT_DIALOG_ACTION,
  CONTACT_PERSON_DELETE_ACTION,
  CONTACT_PERSON_SHOW_DELETE_DIALOG_ACTION,
  CONTACT_PERSON_HIDE_DELETE_DIALOG_ACTION,

  CONTACT_PERSON_GET_PAGE_ITEMS_ACTION,
  CONTACT_PERSON_SET_ORDER_ACTION,
  CONTACT_PERSON_SET_LIMIT_ACTION,
  CONTACT_PERSON_SET_OFFSET_ACTION,
  CONTACT_PERSON_SET_SEARCH_ACTION,
  CONTACT_PERSON_RESET_SEARCH_ACTION,
  CONTACT_PERSON_SET_FILTER_QUERIES_ACTION
} from './actions.js'

const initialState = pageDefault('name', 'contact-person')

/**
 * Reducer for handling contact persons.
 */
export function contactPerson (state = initialState, action) {
  let normalizedItems = {}
  switch (action.type) {
    // CRUD
    case CONTACT_PERSON_GET_OK_ACTION:
      normalizedItems = { ...state.normalizedItems }
      action.items.reduce((_, item) => {
        normalizedItems[item.id] = item
        return normalizedItems
      }, {})
      return {
        ...state,
        items: action.items.slice(),
        normalizedItems
      }
    case CONTACT_PERSON_CREATE_ACTION: {
      const { contactPerson } = action
      normalizedItems = { ...state.normalizedItems }
      normalizedItems[contactPerson.id] = contactPerson
      return {
        ...state,
        items: state.items.concat(contactPerson),
        pageItems: [contactPerson].concat(state.pageItems),
        normalizedItems
      }
    }
    case CONTACT_PERSON_UPDATE_ACTION: {
      const { contactPerson } = action
      normalizedItems = { ...state.normalizedItems }
      normalizedItems[contactPerson.id] = contactPerson
      return {
        ...state,
        items: state.items.map(c => c.id === contactPerson.id ? contactPerson : c),
        pageItems: state.pageItems.map(c => c.id === contactPerson.id ? contactPerson : c),
        normalizedItems
      }
    }
    case CONTACT_PERSON_DELETE_ACTION: {
      const { contactPerson } = action
      normalizedItems = { ...state.normalizedItems }
      delete normalizedItems[contactPerson.id]
      return {
        ...state,
        items: state.items.filter(c => c.id !== contactPerson.id),
        pageItems: state.pageItems.filter(c => c.id !== contactPerson.id),
        normalizedItems
      }
    }

    // Dialogs
    case CONTACT_PERSON_SHOW_ADD_DIALOG_ACTION:
      return { ...state, addItem: true }
    case CONTACT_PERSON_HIDE_ADD_DIALOG_ACTION:
      return { ...state, addItem: false }
    case CONTACT_PERSON_SHOW_EDIT_DIALOG_ACTION:
      return { ...state, editItem: action.contactPerson }
    case CONTACT_PERSON_HIDE_EDIT_DIALOG_ACTION:
      return { ...state, editItem: null }
    case CONTACT_PERSON_SHOW_DELETE_DIALOG_ACTION:
      return { ...state, deleteItem: action.contactPerson }
    case CONTACT_PERSON_HIDE_DELETE_DIALOG_ACTION:
      return { ...state, deleteItem: null }

    // Pagination
    case CONTACT_PERSON_GET_PAGE_ITEMS_ACTION:
      normalizedItems = { ...state.normalizedItems }
      action.pageItems.reduce((_, item) => {
        normalizedItems[item.id] = item
        return normalizedItems
      }, {})
      return { ...state, pageItems: action.pageItems, normalizedItems }
    case CONTACT_PERSON_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return { ...state, order, orderBy, offset: 0 }
    }
    case CONTACT_PERSON_SET_LIMIT_ACTION:
      return { ...state, limit: action.limit, offset: 0 }
    case CONTACT_PERSON_SET_OFFSET_ACTION:
      return { ...state, offset: action.offset }
    case CONTACT_PERSON_SET_SEARCH_ACTION:
      return { ...state, search: action.search, offset: 0 }
    case CONTACT_PERSON_RESET_SEARCH_ACTION:
      return { ...state, search: '', offset: 0 }
    case CONTACT_PERSON_SET_FILTER_QUERIES_ACTION:
      return { ...state, filterQueries: action.filterQueries, offset: 0 }
    default:
      return state
  }
}
