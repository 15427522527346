import React, { memo, Fragment } from 'react'
import {
  styled,
  Grid,
  LinkStyle,
  Link
} from '@pergas-common/pergas-components'
import {
  Phone,
  Email,
  Contact,
  Teams,
  OpenExternal
} from '@pergas-common/pergas-icons'
import { Link as RLink } from 'react-router-dom'
import { withDataGrid } from './GridHoc'
import useUrlQuery from '../hooks/useUrlQuery'

const ReactRouterLink = styled(RLink)`
  ${LinkStyle};
  display: inherit;
`

const stopPropagation = (e) => e.stopPropagation()

const items = ({ result }) => {
  const query = useUrlQuery()
  const currentlyViewed = query.get('id')
  const withoutCurrentlyViewed = Array.isArray(result)
    ? result.filter(({ id }) => {
        return parseInt(currentlyViewed) !== id
      })
    : []

  const contactPersonRow = withoutCurrentlyViewed.map((person) => {
    const {
      id,
      name,
      object_type_name: collectionTypeName,
      address: {
        phone,
        email
      }
    } = person
    return (
      <Fragment key={id}>
        <Grid.Item><Contact width={18} height={18} /><span><ReactRouterLink to={`/contact-persons/edit?id=${id}`}>{!!name && name}</ReactRouterLink></span></Grid.Item>
        <Grid.Item><span>{!!collectionTypeName && collectionTypeName}</span></Grid.Item>
        <Grid.Item><span>{!!phone && <Link href={`tel:${phone}`}>{phone}</Link>}</span></Grid.Item>
        <Grid.Item actions>
          <span>{!!email && <Link onClickHandler={stopPropagation} href={`https://teams.microsoft.com/l/call/0/0?users=${email}`}><Teams width={20} height={20} /></Link>}
          </span>
          <span>{!!phone && <Link href={`tel:${phone}`}><Phone width={20} height={20} /></Link>}</span>
          <span>{!!email && <Link href={`mailto:${email}`}><Email width={16} height={16} /></Link>}</span>
          <span>{!!id && <ReactRouterLink to={`/contact-persons/edit?id=${id}`}><OpenExternal width={20} height={20} /></ReactRouterLink>}</span>
        </Grid.Item>
      </Fragment>
    )
  })
  return (
    <>
      {contactPersonRow}
    </>
  )
}
export const ContactPersonGrid = memo(items)
export const ContactPersonGridHoc = memo(withDataGrid(items))
