import clone from 'clone-deep'
import {
  FILES_GET_ROOT_FOLDER_ACTION,
  FILES_GET_SUB_FOLDER_ACTION,
  FILES_BEGIN_SEARCH_ACTION,
  FILES_END_SEARCH_ACTION,
  FILES_RESET_SEARCH_ACTION
} from './actions'

const DEFAULT_SEARCH_STATE = {
  query: '',
  isSearching: false,
  results: null
}

const DEFAULT = {
  // All file trees with key set to `${item.type}:${item.id}` e.g.
  // 'contact:1', 'project:314' etc and each value corresponds
  // to an object with the file tree
  trees: {},
  // We keep search state for each type of page in this state to avoid
  // spreading out redux all over the place
  search: {
    contact: { ...DEFAULT_SEARCH_STATE },
    project: { ...DEFAULT_SEARCH_STATE },
    department: { ...DEFAULT_SEARCH_STATE },
    category: { ...DEFAULT_SEARCH_STATE }
  }
}

/**
 * Reducer for the file view.
 */
export function files (state = DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case FILES_GET_ROOT_FOLDER_ACTION: {
      const { siteItem, path, children } = action
      if (!siteItem.url) {
        return state
      }
      const { trees } = state

      let newTree
      const treeId = `${siteItem.type}:${siteItem.id}`
      const tree = trees[treeId]

      if (tree) {
        if (!equalItems(tree.children, children)) {
          newTree = { ...tree, children }
        } else {
          newTree = tree
        }
      } else {
        newTree = {
          name: siteItem.name,
          id: 'root',
          nodeId: 'root',
          path,
          type: 'rootfolder',
          children
        }
      }

      return {
        ...state,
        trees: {
          ...trees, [treeId]: newTree
        }
      }
    }

    case FILES_GET_SUB_FOLDER_ACTION: {
      const { siteItem, parent, children } = action
      const { trees } = state
      const treeId = `${siteItem.type}:${siteItem.id}`
      const tree = trees[treeId]

      if (tree) {
        const copy = clone(tree)

        let replaced = false
        mapTree(copy, (i) => {
          if (i && i.id === parent.id) {
            if (!equalItems(i.children, children)) {
              i.children = children
              replaced = true
            }
            return true
          }
        })

        if (!replaced) {
          return state
        }

        return {
          ...state,
          trees: {
            ...trees,
            [treeId]:
            copy
          }
        }
      }

      return state
    }

    case FILES_BEGIN_SEARCH_ACTION: {
      const { siteItem, query } = action
      const { search } = state
      const itemType = siteItem.type
      const currentSearchState = state.search[itemType]
      const newSearchState = { ...currentSearchState, query, isSearching: true }
      return {
        ...state,
        search: {
          ...search,
          [itemType]: newSearchState
        }
      }
    }

    case FILES_END_SEARCH_ACTION: {
      const { siteItem, results } = action
      const { search } = state
      const itemType = siteItem.type
      const currentSearchState = state.search[itemType]
      const newSearchState = { ...currentSearchState, results, isSearching: false }
      return {
        ...state,
        search: {
          ...search,
          [itemType]: newSearchState
        }
      }
    }

    case FILES_RESET_SEARCH_ACTION: {
      const { search } = state
      const { itemType } = action
      return {
        ...state,
        search: {
          ...search,
          [itemType]: { ...DEFAULT_SEARCH_STATE }
        }
      }
    }

    default:
      return state
  }
}

/**
 * Sort items based on id.
 */
function sortItems (items) {
  return items.slice().sort((A, B) => {
    const a = A.id
    const b = B.id
    if (a < b) return -1
    else if (a > b) return 1
    else return 0
  })
}

/**
 * Compare two item arrays. Simple comparison to avoid rewriting
 * root item array if it's not needed.
 */
function equalItems (_lhs, _rhs) {
  if (_lhs.length !== _rhs.length) return false
  const lhs = sortItems(_lhs)
  const rhs = sortItems(_rhs)
  for (let i = 0; i < lhs.length; i++) {
    if (lhs[i].name !== rhs[i].name) return false
  }
  return true
}

/**
 * Takes an array and traverses down the tree.
 * Calls iterator with current element and it's parent, if any.
 */
function mapTree (tree, iterator) {
  function map (item) {
    if (!iterator(item)) {
      if (Array.isArray(item.children)) {
        item.children.slice().forEach(child => {
          map(child, item)
        })
      }
    }
  }
  tree.children.forEach(item => map(item))
}
