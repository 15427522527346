import { createSelector } from '@reduxjs/toolkit'

export const makeSelectorByIsFavorite = () => createSelector(
  (state) => state.contact,
  (_, id) => id,
  (slice, id) => {
    const contact = slice.items.find(({ id: _id }) => _id === Number(id))
    return contact?.is_favorite
  }
)

export const makeSelectorBySharepointStatus = () => createSelector(
  (state) => state.contact,
  (_, id) => id,
  (slice, id) => {
    const item = slice.items.find((item) => item.id === Number(id))
    return item && item.sharepoint_status
  }
)

export const makeSelectorBySharepointUrl = () => createSelector(
  (state) => state.contact,
  (_, id) => id,
  (slice, id) => {
    const item = slice.items.find((item) => item.id === Number(id))
    return item && item.url
  }
)

export const makeSelectorById = () => createSelector(
  (state) => state.contact,
  (_, id) => id,
  (slice, id) => {
    return slice.items?.find(({ id: _id }) => _id === Number(id))
  }
)

export const makeSelectorContact = () => createSelector(
  (state) => state.contact,
  (slice) => {
    return slice.items || []
  }
)

export const makeSelectorSelectedItemId = () => createSelector(
  (state) => state.contact,
  (slice) => {
    return slice.selectedItemId
  }
)

export const makeSelectorEidById = () => createSelector(
  (state) => state.contact,
  (_, id) => id,
  (slice, id) => {
    const contact = slice.items?.find(({ id: _id }) => _id === Number(id))
    return contact?.eid
  }
)

export const makeSelectorIsSearching = () => createSelector(
  (state) => state.contact,
  (slice) => {
    return slice.isSearching
  }
)

export const makeSelectorLimit = () => createSelector(
  (state) => state.contact,
  (slice) => {
    return slice.limit
  }
)

export const makeSelectorOffset = () => createSelector(
  (state) => state.contact,
  (slice) => {
    return slice.offset
  }
)
