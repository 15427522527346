import { createSelector } from '@reduxjs/toolkit'

export const makeSelectorPermissions = () => createSelector(
  (state) => state.login.permissions,
  (permissions) => {
    return permissions
  }
)

export const makeSelectorPermissionContactPersons = () => createSelector(
  (state) => state.login.permissions,
  (permissions) => {
    return permissions.contactPerson
  }
)

export const makeSelectorPermissionContact = () => createSelector(
  (state) => state.login.permissions,
  (permissions) => {
    return permissions.contact
  }
)

export const makeSelectorPermissionProject = () => createSelector(
  (state) => state.login.permissions,
  (permissions) => {
    return permissions.project
  }
)

export const makeSelectorPermissionTicket = () => createSelector(
  (state) => state.login.permissions,
  (permissions) => {
    return permissions.ticket
  }
)
