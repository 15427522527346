import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Button, DialogContentText } from '@mui/material'
import DialogBase, { actionButtonStyle, actionIconStyle } from './DialogBase'
import { RED_COLOR } from '../style'
import { Close, Warning } from '@mui/icons-material'
import { Modal } from '@pergas-common/pergas-components'
import { Remove } from '@pergas-common/pergas-icons'
import { DeleteItemIcon } from '../icons'
import { selectLocale } from '../../redux/locale/selectors'
import { Footer, Body } from './Base'

const deleteButtonStyle = Object.assign(
  {},
  actionButtonStyle(),
  {
    color: RED_COLOR,
    marginRight: '5px'
  }
)

class DeleteItem extends DialogBase {
  constructor (props) {
    super(props)
    const { strings } = this.props
    this.maxWidth = 'sm'
    this.title = {
      text: strings.warning,
      Icon: Warning,
      IconColor: RED_COLOR
    }
  }

  renderActions () {
    const { strings, onOk, onCancel } = this.props
    return (
      <>
        <Button
          onClick={onOk}
          style={actionButtonStyle()}
        >
          <DeleteItemIcon style={deleteButtonStyle} />{strings.delete}
        </Button>
        <Button
          onClick={onCancel}
          style={actionButtonStyle()}
        >
          <Close style={actionIconStyle} />{strings.cancel}
        </Button>
      </>
    )
  }

  renderContent () {
    const { strings, text } = this.props
    return (
      <DialogContentText style={{ padding: '15px 10px' }}>
        {strings.reallydelete} <b>{text}</b> ?
      </DialogContentText>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

/*
  I wIll proxy deleteItem to avoid having to perform a million code changes at once in case of breaking changes.
*/
export const DeleteItemV2 = ({ text, isOpen, onSubmit, onCloseRequest }) => {
  const locale = useSelector(selectLocale)
  return (
    <Modal
      title={locale.warning} onCloseRequest={onCloseRequest} titleIcon={<Warning color='#FF5656' width={20} height={20} />} isOpen={isOpen} size='md' footer={() => (
        <Footer onConfirm={onSubmit} confirmIcon={<Remove color='#FF5656' width={20} height={20} />} confirmLabel={locale.delete} onCloseRequest={onCloseRequest} />
      )}
    >
      {() => (
        <Body>
          <span>{locale.reallydelete} <b>{text}</b></span>
        </Body>
      )}
    </Modal>
  )
}

export default connect(mapStateToProps)(DeleteItem)
