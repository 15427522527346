import React from 'react'
import {
  spacing,
  styled
} from '@pergas-common/pergas-components'
import { css } from 'styled-components'

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: 120px;
  flex-grow: 1;
  > div > small {
      ${({ theme }) => theme.typography.breadcrumb};
      font-weight: bold;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
`

const Grouped = styled.fieldset`
  display: flex;
  position: relative;
  padding: ${spacing(1)};
  margin: 16px;
  border-color: ${({ theme }) => theme.palette.border.primary};
  border-width: .5px;
  border: ${({ border = false }) => !border && 'none'}; 
  > legend {
    ${({ theme }) => theme.typography.breadcrumb};
  }
  > h1 {
    position: absolute;
    top: ${spacing(-2)};
    ${({ theme }) => theme.typography.subTitle};
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  ${({ offset }) => offset && css`
    position: relative;
    bottom: ${spacing(offset)};
`};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${({ offset }) => offset && css`
      position: relative;
      bottom: ${spacing(offset)};
  `};
 `

const Content = ({ children }) => {
  return (
    <Holder>
      <Inner>
        {children}
      </Inner>
    </Holder>
  )
}

export default { Column, Row, Content, Grouped }
