import React from 'react'

// TODO this component is very rudimentary and should
// maybe be in svg format with text component
// Also, we might want some sort of cross to remove tags
// easily.
const ColoredTag = ({ name, color }) => {
  return (
    <div style={coloredTagStyle(color)}>
      <span>{name}</span>
    </div>
  )
}

export default ColoredTag

// TODO we should calculate the text color so it fits with any
// background, e.g. sometimes white and sometimes black
const coloredTagStyle = (color) => {
  return {
    borderRadius: '0.2em',
    backgroundColor: color,
    color: 'white',
    float: 'left',
    fontWeight: '300',
    lineHeight: '1.8',
    padding: '0 0.5em',
    marginRight: '0.2em'
  }
}
