import React, { useState, useEffect } from 'react'
import { spacing, styled } from '@pergas-common/pergas-components'
import { ArrowDown, ArrowUp } from '@pergas-common/pergas-icons'
import { TOOLBAR_BG_COLOR } from './style.js'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  cursor: ${({ isDragging }) => isDragging ? 'ns-resize' : 'default'};
`

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 50px;
  background: ${TOOLBAR_BG_COLOR};
  align-items: center;
  justify-content: space-between;
`

const ResizeSlider = styled.div`
  width: 100%;
  height: 5px;
  border-top: 1px solid #a3a3a3;
  border-left: 1px solid #a3a3a3;
  border-right: 1px solid #a3a3a3;
  background: rgb(202, 221, 204);
  cursor: ns-resize;
`

const LeftSideHolder = styled.div`
  padding-left: ${spacing(1)};
  display: flex;
  align-items: center;
`

const HeaderTitle = styled.span`
  color: ${({ theme }) => theme.palette.common.primary};
  ${({ theme }) => theme.typography.breadcrumb};
  font-weight: bold;
  margin: 0 0 0 8px;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
`

const LeftButtons = styled.div`
  color: white;
  margin-left: 20px;
`

const CenterHolder = styled.div`
  max-width: 286px;
  width: 100%;
`

const RightSideHolder = styled.div`
  color: white;
`

const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  overflow-y: auto;
`

const Accordion = ({
  isOpen,
  title,
  initialHeight,
  left,
  center,
  right,
  onShowToggle,
  onSetHeight,
  children
}) => {
  const [expanded, setExpanded] = useState(isOpen)

  const EXPANDED_HEIGHT = initialHeight || '50vh'
  const MINIMIZED_HEIGHT = '50px'
  const [height, setHeight] = useState(expanded ? EXPANDED_HEIGHT : MINIMIZED_HEIGHT)

  useEffect(() => {
    setHeight(expanded ? EXPANDED_HEIGHT : MINIMIZED_HEIGHT)
  }, [expanded])

  const [dragState, setDragState] = useState(null)
  const isDragging = !!dragState

  const handleClick = () => {
    onShowToggle && onShowToggle()
    setExpanded(!expanded)
  }

  const onMouseDown = (e) => {
    e.preventDefault()
    const { pageY } = e
    const parentHeight = e.target.parentElement.clientHeight
    setDragState({ pageY, parentHeight })
  }

  const onMouseMove = (e) => {
    if (isDragging) {
      const { pageY, parentHeight } = dragState
      setHeight(`${parentHeight - (e.pageY - pageY)}px`)
    }
  }

  const onMouseUp = (e) => {
    if (isDragging) {
      setDragState(null)
      onSetHeight(height)
    }
  }

  const Arrow = expanded ? ArrowDown : ArrowUp

  return (
    <Wrapper
      style={{ height }}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      isDragging={isDragging}
    >
      <HeaderContainer>
        <LeftSideHolder>
          <Arrow
            width={16}
            height={16}
            color='white'
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
          />
          <HeaderTitle>{title}</HeaderTitle>
          {expanded && left && <LeftButtons>{left}</LeftButtons>}
        </LeftSideHolder>
        {center && <CenterHolder>{center}</CenterHolder>}
        {right && <RightSideHolder>{right}</RightSideHolder>}
      </HeaderContainer>
      <ResizeSlider onMouseDown={onMouseDown} />
      <ContentContainer style={{ display: expanded ? 'flex' : 'none' }}>
        {children}
      </ContentContainer>
    </Wrapper>
  )
}

export default Accordion
