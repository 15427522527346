import React, { Fragment } from 'react'
import { useTable, useSortBy, usePagination, useExpanded, useRowSelect } from 'react-table'
import styled, { spacing } from '../theme/index.jsx'
import { SortDesc, SortAsc, SortIdle } from '@pergas-common/pergas-icons'

const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  height: 1px;
  border-spacing: 0;
  border-collapse: collapse;
  overflow-y: hidden;

  box-shadow: 0 8px 5px -11px ${({ theme }) => theme.palette.common.secondary};
`

const StyledCell = styled.td`
  padding: 0 ${spacing(1)};
  height: 48px;
  border: 1px solid #e4e9f0;
  &:first-child: {
    border-left: none;
  }
`

const StyledHead = styled.th`
  padding: 0 ${spacing(1)};
  height: 47px;
  border: 1px solid #e4e9f0;
  color: #FFFFFF;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  line-height: 1.5rem;
  font-weight: ${({ strong }) => (strong ? 'bold !important' : 'normal')};
  width: ${({ size }) => {
    if (size === 'xsm') {
      return '100px'
    }
    if (size === 'sm') {
      return '200px'
    }
    if (size === 'md') {
      return '300px'
    }
    return '100%'
  }};
  text-align: left;
  svg {
    vertical-align: middle;
  }
  &:first-child: {
    border-left: none;
  },
`

const TableRow = styled.tr`
  height: 47px;
  &:hover: {
    cursor: ${({ onClick }) => onClick && 'pointer'};
    background: ${({ interactive = true }) => interactive && '#f7f9fb'};
  }
`

export const CellHolder = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme, isTh }) =>
    isTh ? theme.typography.subTitle : theme.typography.body};
`

const Inner = styled.div`
  display: flex;
  min-width: 50px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`

const InnerChild = styled.div`
  display: flex;
  align-items: center;
`

const TableHeadRow = styled.thead`
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.palette.table.primary};
  display: table-header-group;
`

const TableHeadSpan = styled.span`
  user-select: none;
`

const SortHolder = styled.div`
  display: inline-block;
  margin-left: ${spacing(0.5)};
`

const TableHead = ({
  children,
  onClick,
  icons,
  isSortable,
  isSorted,
  isSortedDesc,
  ...props
}) => {
  return (
    <StyledHead onClick={onClick} {...props} strong>
      <CellHolder isTh>
        {children}
        <SortHolder>
          {isSorted && (isSortedDesc ? <SortDesc /> : <SortAsc />)}
          {isSortable && !isSorted && <SortIdle />}
        </SortHolder>
      </CellHolder>
    </StyledHead>
  )
}

const TableCell = ({ className, children, left, center, right, ...rest }) => {
  return (
    <StyledCell {...rest}>
      <CellHolder>
        {children || (
          <Inner>
            {left && <InnerChild>{left}</InnerChild>}
            {center && <InnerChild>{center}</InnerChild>}
            {right && <InnerChild>{right}</InnerChild>}
          </Inner>
        )}
      </CellHolder>
    </StyledCell>
  )
}

const TableBody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
`

export const THead = (props) => <TableHeadRow {...props} />
export const Th = (props) => <TableHead {...props} />
export const Tr = (props) => <TableRow {...props} />
export const Td = (props) => <TableCell {...props} />
export const Tb = (props) => <TableBody {...props} />
export const Tf = (props) => <tfoot {...props} />

export const Table = ({
  data,
  children,
  manualSortBy = true,
  columns,
  onRowClick,
  renderRowSubComponent,
  initialPageSize = 10,
  selectedRowIds = {},
  initialSortBy = []
}) => {
  const tableProps = useTable(
    {
      getResetExpandedDeps: false,
      paginateExpandedRows: false,
      autoResetHiddenColumns: false,
      disableMultiSort: true,
      manualSortBy: manualSortBy,
      columns,
      data,
      initialState: {
        pageSize: initialPageSize,
        sortBy: initialSortBy,
        selectedRowIds
      },
      autoResetSelectedRows: true
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  )
  const {
    page,
    headerGroups,
    prepareRow,
    pageCount,
    gotoPage,
    setPageSize,
    toggleAllPageRowsSelected,
    state: { pageIndex, pageSize }
  } = tableProps

  const renderedChildren = children && children({ pageCount, pageSize, pageIndex, gotoPage, setPageSize })

  return (
    <StyledTable {...tableProps.getTableProps()}>
      <>
        <THead>
          {headerGroups.map((headerGroup, i) => (
            <Tr key={i} interactive={false} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(
                ({ manualSort, isSortable = true, ...column }) => {
                  return (
                    <Th
                      key={column.id}
                      size={column.size}
                      bold={column.bold}
                      isSorted={column.isSorted}
                      isSortedDesc={column.isSortedDesc}
                      isSortable={isSortable}
                      {...column.getHeaderProps(
                        isSortable && column.getSortByToggleProps()
                      )}
                      onClick={(e) => {
                        if (isSortable) {
                          const { onClick } = column.getSortByToggleProps()
                          onClick && onClick(e)
                          manualSort && manualSort(column)
                        }
                      }}
                    >
                      <TableHeadSpan>{column.render('Header')}</TableHeadSpan>
                    </Th>
                  )
                }
              )}
            </Tr>
          ))}
        </THead>
        <Tb>
          {page.map((row) => {
            prepareRow(row)
            const styleProps = {}
            if (row.isSelected) {
              styleProps.style = { background: '#DAE1E5' }
            }
            return (
              <Fragment key={row.getRowProps().key}>
                <Tr
                  {...styleProps}
                  onClick={
                    onRowClick
                      ? (event) => {
                          onRowClick(event, row, toggleAllPageRowsSelected)
                        }
                      : undefined
                  }
                >
                  {row.cells.map((cell) => {
                    const cRenderer = cell.render('Cell', {
                      ...cell.getCellProps()
                    })
                    if (cell.column.accessor) {
                      return <Td {...cell.getCellProps()}>{cRenderer}</Td>
                    }
                    return cRenderer
                  })}
                </Tr>
                {row.isExpanded &&
                 renderRowSubComponent &&
                 renderRowSubComponent({ row, toggleAllPageRowsSelected })}
              </Fragment>
            )
          })}
        </Tb>
        {renderedChildren && (
          <Tf>
            <Tr>
              <>
                {renderedChildren}
              </>
            </Tr>
          </Tf>
        )}
      </>
    </StyledTable>
  )
}

export default Table
