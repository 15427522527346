import React from 'react'
import { connect } from 'react-redux'
import { TextField } from '@mui/material'
import { RoleIcon } from '../icons'
import EditBase from './EditBase'

const inputLabelProps = { shrink: true }

class EditRole extends EditBase {
  constructor (props) {
    super(props)

    const { strings } = this.props
    this.title = { text: strings.role, Icon: RoleIcon }

    const item = Object.assign({
      name: '',
      description: ''
    }, props.item)

    const saveDisabled = this.isSaveDisabled(item)
    this.state = {
      item,
      saveDisabled
    }
  }

  isSaveDisabled (item) {
    return item.name.length === 0
  }

  handleChange (prop) {
    return (event) => {
      const { value } = event.target
      const { item } = this.state
      item[prop] = value
      const saveDisabled = this.isSaveDisabled(item)
      this.setState({ item, saveDisabled })
    }
  }

  renderContent () {
    const { strings } = this.props
    const { item } = this.state

    return (
      <>
        <TextField
          size='small'
          margin='dense'
          variant='outlined'
          label={strings.name}
          value={item.name || ''}
          fullWidth
          onChange={this.handleChange('name')}
          InputLabelProps={inputLabelProps}
          autoFocus
          required
        />
        <TextField
          size='small'
          margin='dense'
          variant='outlined'
          label={strings.description}
          value={item.description || ''}
          fullWidth
          onChange={this.handleChange('description')}
          InputLabelProps={inputLabelProps}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

export default connect(mapStateToProps)(EditRole)
