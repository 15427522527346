import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { ListItem } from '@mui/material'
import { selectLocale } from '../../redux/locale/selectors'
import AddBase from './AddBase'
import ColoredTag from '../ColoredTag'
import { sortArray } from '../../util'
import { styled, Group, Button, Modal, DropdownSelectColor } from '@pergas-common/pergas-components'
import { Tag, Add, Close } from '@pergas-common/pergas-icons'
import { api } from '../../redux'
import useRequest from '../../hooks/useRequest'
import useDebounce from '../../hooks/useDebounce'

const Holder = styled.div`
  display: flex;
  height: 130px;
  flex-direction: column;
`

const SelectGroupHolder = styled.div`
  width: 100%;
  position: relative;
  padding: 0 8px;
  display: flex;
  flex-direction: column-reverse;
  > span {
    margin-left: 8px;
    ${({ theme }) => theme.typography.breadcrumb};
    color: ${({ theme }) => theme.palette.brand.secondary};
  }
  > div {
    background: #FFFFFF;
  }
`

const ContentHolder = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  align-self: center;
`

export const AddTagV2 = ({ tagType, isOpen, onSelect, onCloseRequest }) => {
  const locale = useSelector(selectLocale)
  const [query, data, status] = useRequest(api.getTags)
  const [tag, setTag] = useState(null)
  const [debouncedRequest] = useDebounce(query, 300)

  useEffect(() => {
    query({ query: [{ key: 'tag_type_name', op: '=', value: tagType }], sort: 'name.asc', limit: 50 })
  }, [query])

  return (
    <Modal
      title={locale.add_tag} onCloseRequest={onCloseRequest} titleIcon={<Tag width={20} height={20} />} isOpen={isOpen} size='md' footer={() => (
        <Group.Button>
          <Button onClick={() => {
            onCloseRequest && onCloseRequest()
          }}
          ><span>{locale.cancel}</span>
            <Close width={20} height={18} color='#28afe0' />
          </Button>
          <Button
            disabled={!tag} onClick={() => {
              if (tag) {
                onSelect(tag)
                onCloseRequest && onCloseRequest()
              }
            }}
          ><span>{locale.add}</span>
            <Add width={20} height={20} color='#28afe0' />
          </Button>
        </Group.Button>
      )}
    >
      {() => (
        <Holder>
          <ContentHolder>
            <SelectGroupHolder>
              <DropdownSelectColor
                isFetching={status === 'pending'} buttonLabel='' items={data === null ? [] : data} handleSelectedItemChange={({ selectedItem, inputValue }) => {
                  if (selectedItem?.name !== inputValue) {
                    debouncedRequest({
                      query: [{ key: 'name', op: '~', value: inputValue }, { key: 'tag_type_name', op: '=', value: tagType }],
                      sort: 'name.asc',
                      limit: 50
                    })
                  }
                  setTag(selectedItem)
                }}
              />
              <span>{locale.name}</span>
            </SelectGroupHolder>
          </ContentHolder>
        </Holder>
      )}
    </Modal>
  )
}

class AddTag extends AddBase {
  constructor (props) {
    super(props)
    const { strings } = this.props
    this.title.text = strings.add_tag
  }

  createList () {
    const { items, onSelect } = this.props
    return sortArray(items, 'name').map(item => {
      return (
        <ListItem button key={item.id} onClick={onSelect.bind(null, item)}>
          <ColoredTag name={item.name} color={item.color} />
        </ListItem>
      )
    })
  }
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

export default connect(mapStateToProps)(AddTag)
